import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_SIGN_TEMPLATE = {
    text_search: "",
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}

export const SIGN_DATE = {
    SERVER: 1,
    CLIENT: 2
}
