import moment from 'moment';

export const RANGER_DATE_CALENDAR = '1900:2100';

export const PAGES = [5, 10, 20, 50, 100];

export const ROW_DOC_DEFAULT = 50;

export const ROW_DEFAULT = 10;
export const PAGE_DEFAULT = 1;

export const MAX_FILE_SIZE_DEFAULT = 20;
export const MAX_FILE_SIGN = 50;

export const OTP_TIME = 300 * 1000;

export const MAX_SIZE_FILE = 2;

export const VI_LANG = 'vi';
export const EN_LANG = 'en';

export const MAX_FILE_INPUT = 6;
export const MAX_FILE_LOT_INPUT = 5;

export const TIME_SIGN_TOKEN = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');

export const MAX_TIME_CONTRACT = 1000;
export const MAX_TIME_PROCEDURE = 30;

export const APP_SIGN = window['runConfig'].REACT_APP_SIGN;
export const APP_ECONTRACT = window['runConfig'].REACT_APP_ECONTRACT;

export const SYSTEM_APP_SIGN = 1;
export const SYSTEM_APP_ECONTRACT = 2;

export const IS_OTP_SIGN = 1;
export const IS_NOT_OTP_SIGN = 2;

export const CAPACITY_DEFAULT = 0;

export const IS_SHARE_CAPACITY = 1;
export const IS_NOT_SHARE_CAPACITY = 2;

export const TEST_SYSTEM = 2;

export const IS_SEARCH_NUM = 8;
